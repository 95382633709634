import React from "react"

const PG_P_PROFITS_CONTENT = {
  firstListOfStrings: [
    "Monthly Sales Estimates",
    "Revenue Per Review Parameter",
    "Review Numbers and Rating Averages",
    "Google Trends",
    "Automatic FBA Profit Calculator",
  ],
  secondListOfStrings: [
    "Lowest Seller Price Information",
    "Listing Quality Analysis",
    "Product Score for Private Label and Reselling",
    "Main Product Keywords",
    "BSR History",
  ],
}
export default PG_P_PROFITS_CONTENT
