import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../../helpers/constants";
import { graphql, useStaticQuery } from "gatsby";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import StandardFormBlockContainer from "../../../containers/common/forms/StandardFormBlockContainer";
import FastImage from "../../../containers/common/FastImage";
import "./index.scss";

const HomeIntro = () => {

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { regex: "/pro-homeIntro/" }, ext: { eq: ".png" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  return (
    <section className="PgP-HomeIntro">
      <div className="container">
        <div className="PgP-HomeIntro-row">
          <div className="PgP-HomeIntro-info">
            <div className="PgP-HomeIntro__title">
              <h1>AMZScout PRO Extension</h1>
              <p>Check the Product Potential and Level of Competition</p>
            </div>
            <ul className="PgP-HomeIntro-points">
              <li>
                <img src={PointIcon} alt="" />
                <p>Find profitable products based on solid sales statistics</p>
              </li>
              <li>
                <img src={PointIcon} alt="" />
                <p>
                  Estimate the income you can expect as a new seller in an
                  existing niche
                </p>
              </li>
              <li>
                <img src={PointIcon} alt="" />
                <p>
                  Discover verified local and global suppliers to start selling
                  your products with simpler shipping
                </p>
              </li>
            </ul>
            <StandardFormBlockContainer
              buttonTextFirstState="Try PRO Extension For Free"
              buttonTextSecondState="TRY PRO EXTENTION FOR FREE!"
              page={PAGES.PRO}
              customClass="PgP-HomeIntro-emailCollect"
              placeholder="Enter your email to start your FREE TRIAL!"
              showMobileProPopup
              isIntro
            />
          </div>
          <div className="PgP-HomeIntro-screen">
            <FastImage
              imageData={data}
              wrapImageClassName="PgP-HomeIntro-screen__img"
              imageAltName="home image"
              isAdaptive
              isWithRatio
              defaultName="pro-homeIntro"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;

HomeIntro.propTypes = {
  customLocalSave: PropTypes.array,
  onSuccess: PropTypes.func,
};
