import PngDesk from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout.png"
import PngDesk2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@2x.png"
import WebpDesk from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout.webp"
import WebpDesk2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@2x.webp"

import PngMid from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mid.png"
import PngMid2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mid2x.png"
import WebpMid from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mid.webp"
import WebpMid2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mid2x.webp"

import PngTab from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@tab.png"
import PngTab2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@tab2x.png"
import WebpTab from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@tab.webp"
import WebpTab2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@tab2x.webp"

import PngMob from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mob.png"
import PngMob2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mob2x.png"
import WebpMob from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mob.webp"
import WebpMob2x from "../../../assets/images/raster/pages/pro/UsingAmzscout/pro-usingAmzscout@mob2x.webp"

const PG_P_USING_AMZSCOUT_CONTENT = {
  pictureList: {
    PngDesk,
    PngDesk2x,
    WebpDesk,
    WebpDesk2x,
    PngMid,
    PngMid2x,
    WebpMid,
    WebpMid2x,
    PngTab,
    PngTab2x,
    WebpTab,
    WebpTab2x,
    PngMob,
    PngMob2x,
    WebpMob,
    WebpMob2x,
  },

  firstListData: {
    number: "1.",
    title: "Select a Profitable Product to Sell",
    stringList: [
      "Check the Niche Score to quickly estimate if the niche has potential",
      "Evaluate potential future competition by looking at the Visibility Score",
      "See niche and product history to avoid products that trend down",
      "Estimate your FBA costs for better net profit understanding",
    ],
  },
  secondListData: {
    number: "2.",
    title: "Find Local and Global Suppliers",
    stringList: [
      "Find affordable and reliable suppliers from any country, including the U.S. and China, with the new “Local and global suppliers on Alibaba.com” feature ",
      "Compare suppliers by rating and business activity to find the most reliable partners for your business",
      "Find your competitor's products and add them to your inventory to scale your business",
    ],
  },
  thirdListData: {
    number: "3.",
    title: "Get an Edge Over Your Competitors",
    stringList: [
      "Track competitor’s sales, rank, pricing, keywords, and stock levels",
      "Export a CSV file with that information and download it to perform data analysis in spreadsheets",
    ],
  },
}
export default PG_P_USING_AMZSCOUT_CONTENT
