import React from "react";
import TestimonialsVideos from "../../common/TestimonialsVideos";
import "./index.scss";

const Testimonials = ({ setUrlYoutubeModal }) => (
  <section className="PgP-Testimonials">
    <h2 className="PgP-Testimonials__title">
      Empowering famous sellers and AMZScout Residents
    </h2>
    <TestimonialsVideos setUrlYoutubeModal={setUrlYoutubeModal} />
  </section>
);

export default Testimonials;
