import React from "react";
import PROINSTALL_CONTENT from "../../common/ProInstall/index.content";
import Screen from "../../common/ProInstall/Screen";
import ExtendedScreen from "../../common/ProInstall/ExtendedScreen";
import TrackedLink from "../../Analytics/TrackedLink";
import "./index.scss";

const ProProInstall = () => {
  const { GO_TO, ADD, PRO } = PROINSTALL_CONTENT;
  const url = `${process.env.SCOUT_EXT_PRO}?utm_source=site&utm_medium=amzscout&utm_campaign=pro_lp`;
  return (
    <section className="PgP-PI">
      <div className="container">
        <h2 className="PgP-PI__title">
          Install AMZScout PRO Extension in 3 clicks!
        </h2>
        <p className="PgP-PI__subtitle">
          Get detailed analytics on products from Amazon in real time with this
          tutorial
        </p>
        <div className="PgP-PI__list PgP-PI-list">
          <div className="PgP-PI-list__item">
            <Screen screenshot={GO_TO} classes="PgP-PI-list__img" />
            <p className="PgP-PI-list__num">01.</p>
            <p className="PgP-PI-list__text PgP-PI-list-text">
              Go to the{" "}
              <TrackedLink
                category="Index"
                action="CTA"
                target
                classes="PgP-PI-list-text__link"
                label="PRO Extension for Chrome"
                path={url}
                showMobileProPopup
              >
                PRO Extension
              </TrackedLink>{" "}
              page in the Chrome Store
            </p>
            <TrackedLink
              category="Index"
              action="CTA"
              target
              classes="PgP-PI-list__btn"
              label="PRO Extension for Chrome"
              path={url}
              showMobileProPopup
            >
              Click here
            </TrackedLink>
          </div>
          <div className="PgP-PI-list__item">
            <Screen screenshot={ADD} classes="PgP-PI-list__img" />
            <p className="PgP-PI-list__num">02.</p>
            <p className="PgP-PI-list__text">
              Click the “Add” button and wait 5 seconds
            </p>
          </div>
          <div className="PgP-PI-list__item">
            <ExtendedScreen screenshot={PRO} classes="PgP-PI-list__img" />
            <p className="PgP-PI-list__num">03.</p>
            <p className="PgP-PI-list__text">
              AMZScout PRO Extension is downloaded to your Chrome or Edge
              browser - browse products on the Amazon site and get detailed
              analytics right on the search page
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProProInstall;
