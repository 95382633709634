import React from "react";
import PG_P_PROFITS_CONTENT from "./index.content";
import "./index.scss";

const Profits = () => {
  const { secondListOfStrings, firstListOfStrings } = PG_P_PROFITS_CONTENT;

  return (
    <section className="PgP-Profits">
      <div className="PgP-Profits__container">
        <h2 className="PgP-Profits__title">
          The software is simple to use and navigate. You can base your product
          choice on concrete data to protect your investment:
        </h2>
        <div className="PgP-Profits__list">
          <ul>
            {firstListOfStrings.map((text, i) => (
              <li key={i}>
                <span>+</span>
                {text}
              </li>
            ))}
          </ul>
          <ul>
            {secondListOfStrings.map((text, i) => (
              <li key={i}>
                <span>+</span>
                {text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Profits;
