import React from "react";
import SubList from "./SubList";
import PG_P_USING_AMZSCOUT_CONTENT from "./index.content";
import "./index.scss";

const UsingAmzscout = () => {
  const { pictureList, firstListData, secondListData, thirdListData } =
    PG_P_USING_AMZSCOUT_CONTENT;

  return (
    <section className="PgP-UsingAmzscout">
      <div className="PgP-UsingAmzscout__container">
        <h2 className="PgP-UsingAmzscout__title">
          AMZScout PRO Extension has even more features that will help you
          understand what will bring you better profits
        </h2>
        <div className="PgP-UsingAmzscout__wrap">
          <div className="PgP-UsingAmzscout-info">
            <SubList data={firstListData} parent="PgP-UsingAmzscout-info" />
            <SubList data={secondListData} parent="PgP-UsingAmzscout-info" />
            <SubList data={thirdListData} parent="PgP-UsingAmzscout-info" />
          </div>
          <div className="PgP-UsingAmzscout__wrapImg">
            <picture>
              <source
                type="image/webp"
                media="(min-width: 1366px)"
                srcSet={`${pictureList.WebpDesk} 1x, ${pictureList.WebpDesk2x} 2x`}
              />
              <source
                media="(min-width: 1366px)"
                srcSet={`${pictureList.PngDesk} 1x, ${pictureList.PngDesk2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 1200px) and (max-width: 1365px)"
                srcSet={`${pictureList.WebpMid} 1x, ${pictureList.WebpMid2x} 2x`}
              />
              <source
                media="(min-width: 1200px) and (max-width: 1365px)"
                srcSet={`${pictureList.PngMid} 1x, ${pictureList.PngMid2x} 2x`}
              />
              <source
                type="image/webp"
                media="(min-width: 768px) and (max-width: 1199px)"
                srcSet={`${pictureList.WebpTab} 1x, ${pictureList.WebpTab2x} 2x`}
              />
              <source
                media="(min-width: 768px) and (max-width: 1199px)"
                srcSet={`${pictureList.PngTab} 1x, ${pictureList.PngTab2x} 2x`}
              />
              <source
                type="image/webp"
                media="(max-width: 767px)"
                srcSet={`${pictureList.WebpMob} 1x, ${pictureList.WebpMob2x} 2x`}
              />
              <source
                media="(max-width: 767px)"
                srcSet={`${pictureList.PngMob} 1x, ${pictureList.PngMob2x} 2x`}
              />
              <img src={pictureList.PngDesk} alt="" loading="lazy" />
            </picture>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UsingAmzscout;
