import React from "react";
import PropTypes from "prop-types";

const SubList = ({ data, parent }) => {
  const { title, stringList, number } = data;

  return (
    <div className={`${parent}-item`}>
      <p className={`${parent}-item__title`}>
        <span>{number}</span>
        {title}
      </p>
      <ul>
        {stringList.map((text, i) => (
          <li key={i}>
            <span className={`${parent}-item__point`} />
            <p>{text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

SubList.propTypes = {
  data: PropTypes.object,
  parent: PropTypes.string,
};
export default SubList;
