import React from "react"

const PG_P_RATING_REVIEW_CONTENT = {
  postData: [
    {
      title: "Amazing Extension…",
      description: () =>
        "Amazing Extension. Thanks 5 times for making my Amazon business so easy and so profitable. It’s only and only because of the AMZScout Chrome Extension that I am able to run a successful Amazon business in such a short span of time. Also, the understanding support team clearly explains how to log in and how to use the plugin.",
    },
    {
      title: "I love this product…",
      description: () =>
        "I love this product! As an Amazon FBA seller and also one that manufactures our own products, AMZScout PRO is a great tool to give me a head start on my competition and cut down on product research time when I'm looking for products to manufacture. Would highly recommend this Amazon seller Chrome extension!",
    },
    {
      title: "AMZScout is a must…",
      description: () => (
        <>
          AMZScout is a must if you're a serious Amazon seller. They are
          constantly making updates to provide extended data to help you make
          the most accurate decisions. <br />
          If you asked me what is the best Amazon Chrome extension? The answer
          is definitely an AMZScout extension!
        </>
      ),
    },
  ],
}

export default PG_P_RATING_REVIEW_CONTENT
