import React from "react";
import "./index.scss";

const WhyAmzscout = () => {
  return (
    <section className="PgP-WhyAmzscout">
      <div className="PgP-WhyAmzscout__container">
        <h2 className="PgP-WhyAmzscout__title">
          AMZScout PRO Extension is a Perfect Fit for Beginner and Experienced
          Amazon Sellers
        </h2>
        <p className="PgP-WhyAmzscout__subTitle">
          We have more than 500,000 users and have helped{" "}
          <span>find millions of profitable products already</span>
        </p>
      </div>
    </section>
  );
};

export default WhyAmzscout;
